<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Page Content</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Pages"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>

        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip
              bottom
              v-if="
                hasPermission(
                  'view-module-pagecontent-add-page',
                  this.$route.params.id
                )
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.pageDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Page</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="pages"
            :items-per-page="itemsPerPage"
            no-data-text="No pages found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'module-pagecontent-individual',
                  params: { pageId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'module-pagecontent-individual',
                      params: { pageId: item.id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <PageDialog ref="pageDialog" />
    </v-container>
  </div>
</template>

<script>
import PageDialog from "./components/PageDialog";

export default {
  components: {
    PageDialog,
  },

  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Page", value: "name" },
        { text: "Last Updated By", value: "updated_by_user.full_name" },
        { text: "Last Updated Date", value: "formatted_dates.updated_at" },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
      itemsPerPage: -1,
      breadcrumbs: [
        {
          text: "Page Content",
          disabled: true,
        },
        {
          text: "View All",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    pages() {
      let pages = this.$store.state.pageContent["pages"];

      if (this.searchTerm !== "") {
        pages = pages.filter((p) => {
          const name = p.name.toLowerCase();
          const slug = p.slug.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            slug.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return pages;
    },
  },

  methods: {},
};
</script>
